.card {
  display: inline-flex;
  justify-content: stretch;
  align-items: center;
  flex-direction: column;
  width: 350px;
  max-width: calc(100% - 20px - 20px);
  margin: 20px;
  background: var(--background);
  border-radius: var(--rounded);
  overflow: hidden;
  box-shadow: var(--shadow);
  vertical-align: top;
}

.card[data-style="small"] {
  width: 250px;
}

.card-image img {
  aspect-ratio: 3 / 2;
  object-fit: cover;
  width: 100%;
  // box-shadow: var(--shadow);
}

.card-text {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  max-width: 100%;
  padding: 20px;
}

.card-text > *,
.card-text > .tags {
  margin: 0;
  text-align: left;
}

.card-title {
  font-family: var(--heading);
  font-weight: var(--semi-bold);
}

.card-subtitle {
  margin-top: -15px;
  font-style: italic;
}
